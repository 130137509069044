import { ContactUsField } from "./types";
import { validateField as serverSideFieldValidation } from "src/api";
import { getServerSideValidationErrorMessage } from "@lib/shared/form";
import { AxiosError } from "axios";
export const mainContactUsForm: { [x: string]: ContactUsField } = {
    firstName: {
        name: "firstName",
        label: "First Name",
        placeholder: "First Name",
        inputType: "text",
        type: "text",
        validation: {
            required: true,
            rules: {
                type: "isEmpty",
            },
        },
        autocomplete: "given-name",
        serverSideValidation: true,
    },
    lastName: {
        name: "lastName",
        label: "Last Name",
        placeholder: "Last Name",
        inputType: "text",
        type: "text",
        validation: {
            required: true,
            rules: {
                type: "isEmpty",
            },
        },
        autocomplete: "family-name",
        serverSideValidation: true,
    },
    email: {
        name: "email",
        label: "Email",
        placeholder: "Email",
        inputType: "text",
        type: "email",
        validation: {
            required: true,
            rules: {
                type: "isEmail",
            },
        },
        autocomplete: "email",
        serverSideValidation: true,
    },
    phoneNumber: {
        name: "phone",
        label: "Phone Number",
        placeholder: "Phone Number",
        inputType: "text",
        type: "tel",
        validation: {
            required: true,
            rules: {
                type: "isPhone",
            },
        },
        autocomplete: "tel",
    },
    subject: {
        name: "subject",
        label: "Subject",
        placeholder: "Subject",
        inputType: "text",
        type: "text",
        validation: {
            required: true,
            rules: {
                type: "isEmpty",
                characterCount: {
                    min: 3,
                    max: 255,
                },
            },
        },
    },
    description: {
        name: "body",
        label: "Description",
        placeholder:
            "Please enter the details of your request. A member of our support staff will get back to you within 24 hours",
        inputType: "textarea",
        type: null,
        validation: {
            required: true,
            rules: {
                type: "isEmpty",
                characterCount: {
                    min: 5,
                    max: 255,
                },
            },
        },
    },
    recaptcha: {
        name: "googleReCaptcha",
        label: "",
        placeholder: "",
        inputType: "recaptcha",
        type: null,
        validation: {
            required: true,
        },
    },
};

export const fieldValidation = async (
    field: ContactUsField,
    value: string,
): Promise<{ valid: boolean; message: string }> => {
    const conditions = [];

    const fieldName = field.label ? field.label : field.name;

    if (!value) {
        if (field.validation.required) {
            conditions.push({
                valid: false,
                message: `${fieldName} is required`,
            });
        }
    } else {
        if (field.validation.rules?.type === "isEmail") {
            const reg = new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );

            const isValid = reg.test(String(value).toLowerCase());

            !isValid &&
                conditions.push({
                    valid: false,
                    message: `Please enter a valid ${fieldName}`,
                });
        }

        if (field.validation.rules?.type === "isPhone") {
            const reg = new RegExp(
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            );
            const isValid = reg.test(String(value).toLowerCase());

            !isValid &&
                conditions.push({
                    valid: false,
                    message: `Please enter a valid ${fieldName}`,
                });
        }
        if (field.validation.rules?.type === "isStreetAddress") {
            // must start with a number and contain at least one letter
            const reg = new RegExp(/^\s*\d+\s+([a-zA-Z0-9\s\.,#\-]+)\s*$/);
            const isValid = reg.test(String(value).toLowerCase());

            !isValid &&
                conditions.push({
                    valid: false,
                    message: `Please enter a valid ${fieldName}`,
                });
        }

        if (field.validation.rules?.characterCount?.max) {
            const isValid =
                value.trim().length <=
                field.validation.rules?.characterCount?.max;
            !isValid &&
                conditions.push({
                    valid: false,
                    message: `${fieldName} cannot be more than ${field.validation.rules?.characterCount?.max} characters`,
                });
        }

        if (field.validation.rules?.characterCount?.min) {
            const isValid =
                value.trim().length >=
                field.validation.rules?.characterCount?.min;
            !isValid &&
                conditions.push({
                    valid: false,
                    message: `Please enter at least ${field.validation.rules?.characterCount?.min} characters`,
                });
        }
    }

    if (conditions.length >= 1) {
        return { valid: false, message: conditions[0].message };
    }
    if (field.serverSideValidation) {
        const { error } = await serverSideFieldValidation({
            ruleName: field.name,
            value,
        });
        if (error) {
            return {
                valid: false,
                message: getServerSideValidationErrorMessage(
                    error as AxiosError,
                    fieldName,
                ),
            };
        }
    }
    const valid = true;
    const message = "";
    return { valid, message };
};
