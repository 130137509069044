/* eslint-disable react/display-name */
import React, { CSSProperties, ReactElement, useEffect, useRef } from "react";
import {
    AddressComponent,
    ContactUsField,
    FormattedAddressComponent,
} from "@lib/shared/types";
import ReCAPTCHA from "react-google-recaptcha";
import ConfirmationCheck from "@components/shared/confirmationCheck";
import { Button } from "@components/shared/button";
import { useContactUsForm } from "@hooks/useContactUsForm";
import styles from "./styles.module.scss";
import { mainContactUsForm } from "@lib/shared/contactUsForm";
import { useDomainContext } from "@hooks/useDomainContext";
import { DomainInfo } from "src/api/severalBrandsDomainInfo";

interface Props {
    primaryColor: string;
    secondaryColor: string;
    fields: ContactUsField[];
    domainInfo?: DomainInfo;
    classes?: {
        formClassName?: string;
        fieldClassName?: string;
        fieldWrapperClassName?: string;
        submitButtonClassName?: string;
        thankYouStepClassName?: string;
        fieldErrorClassName?: string;
        selectFieldClassName?: string;
        checkBoxClassName?: string;
        checkboxGroupClassName?: string;
    };
    colors?: {
        thankYouColorIcon?: string;
        thankYouColorText?: string;
    };
    severalBrandsRecaptcha?: boolean;
    submitButtonTitle?: string;
    setSubmittedForm?: React.Dispatch<React.SetStateAction<boolean>>; // to know outside the form component that form is submitted
    doNotSellForm?: boolean;
}

export default function ContactUsForm(props: Props): ReactElement {
    const { severalBrandsRecaptcha } = props;
    const {
        primaryColor,
        secondaryColor,
        fields = severalBrandsRecaptcha
            ? Object.keys(mainContactUsForm)
                  .filter((el) => el !== "recaptcha")
                  .map((itemKey) => mainContactUsForm[itemKey])
            : Object.keys(mainContactUsForm).map(
                  (itemKey) => mainContactUsForm[itemKey],
              ),
        classes,
        domainInfo,
        submitButtonTitle = "send",
        setSubmittedForm,
        doNotSellForm,
        colors,
    } = props;

    const {
        handleFieldBlur,
        handleSubmit,
        handleFieldChange,
        submitted,
        loadingForm,
        formError,
        fieldsState,
    } = useContactUsForm(
        fields,
        severalBrandsRecaptcha,
        setSubmittedForm,
        doNotSellForm,
    );

    return (
        <div
            className={`flex items-center h-full relative ${styles["contact-us-form-wrapper"]} `}
        >
            {!submitted && fieldsState && (
                <form
                    onSubmit={handleSubmit}
                    style={
                        {
                            color: secondaryColor ?? "black",
                            "--primary-color": primaryColor ?? "",
                        } as CSSProperties
                    }
                    className={`${styles["form"]} ${
                        classes?.formClassName ?? ""
                    }`}
                >
                    {fields.map((field) => (
                        <Field
                            key={field.name}
                            field={field}
                            fieldState={fieldsState[field.name]}
                            onChange={handleFieldChange}
                            onBlur={handleFieldBlur}
                            classes={classes}
                            domainInfo={domainInfo}
                            doNotSellForm={doNotSellForm}
                            fields={fields}
                        />
                    ))}

                    {formError && (
                        <div
                            style={{ color: " #f75959" }}
                            className="form-error text-sm font-light mb-3"
                        >
                            {formError}
                        </div>
                    )}

                    <div className="flex flex-col xl:flex-row items-center justify-between mt-4 w-full">
                        <Button
                            style={{
                                backgroundColor: primaryColor ?? "black",
                                color: "white",
                            }}
                            className={`rounded-md relative font-bold flex items-center justify-center px-5 py-3 mt-8 xl:mt-0 w-full lg:w-40 ${
                                classes?.submitButtonClassName ?? ""
                            }`}
                            type="submit"
                            loading={loadingForm}
                        >
                            {submitButtonTitle}
                        </Button>
                    </div>
                </form>
            )}

            <div
                className={`${
                    classes?.thankYouStepClassName ?? ""
                } success flex w-full h-full z-10  flex-col justify-center left-0 top-0 items-center pointer-events-none select-none transition-opacity ${
                    submitted
                        ? "opacity-100 visible"
                        : "opacity-0 invisible absolute"
                }`}
            >
                {submitted && (
                    <ConfirmationCheck
                        color={colors?.thankYouColorIcon ?? primaryColor}
                    />
                )}
                <div className="flex-col justify-center items-center text-center flex">
                    <h2
                        style={{
                            color:
                                colors?.thankYouColorText ??
                                primaryColor ??
                                "black",
                        }}
                        className="text-3xl font-bold"
                    >
                        Thank You
                    </h2>
                    <p
                        className="font-semibold mt-4"
                        style={{
                            color:
                                colors?.thankYouColorText ??
                                primaryColor ??
                                "black",
                        }}
                    >
                        {doNotSellForm
                            ? "Sorry to see you go!"
                            : "Your Message has been sent."}
                    </p>
                    <p
                        className="text-sm mt-1"
                        style={{
                            color:
                                colors?.thankYouColorText ??
                                primaryColor ??
                                "black",
                        }}
                    >
                        {doNotSellForm
                            ? "You have now been removed from our list."
                            : "Our support staff will get back to you within 24 hours"}
                    </p>
                </div>
            </div>
        </div>
    );
}

const Field = ({
    field,
    fieldState,
    onChange,
    onBlur,
    classes,
    domainInfo,
    doNotSellForm,
    fields,
}: {
    field: ContactUsField;
    domainInfo?: DomainInfo;
    fieldState: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    onChange: (field: ContactUsField, val: string) => void;
    onBlur: (field: ContactUsField, val: string) => void;
    classes: Props["classes"];
    doNotSellForm?: boolean;
    fields: ContactUsField[];
}) => {
    const { domain, doNotSellFormStates } = useDomainContext();

    const streetAddressRef = useRef<HTMLInputElement>(null);
    let autoComplete: google.maps.places.Autocomplete;
    const formattedGoogleAddress = (
        addressObject: AddressComponent[],
    ): FormattedAddressComponent => {
        const newAddressObject: FormattedAddressComponent = {};
        addressObject.forEach((addressComponent) => {
            const keyName = addressComponent.types[0];
            newAddressObject[keyName === "postal_code" ? "zipCode" : keyName] =
                addressComponent;
        });
        return newAddressObject;
    };

    const handlePlaceSelect = () => {
        const placeSelectResults = autoComplete.getPlace();

        if (!placeSelectResults?.address_components) {
            // setError("Invalid Street Address");
            // onChange(getZipCodeField(fields), "");

            return;
        }
        const address = formattedGoogleAddress(
            placeSelectResults.address_components,
        );

        const streetNumber = address?.street_number?.short_name ?? "";
        const route = address.route?.long_name ?? "";
        const zipCode = address?.zipCode?.short_name ?? "";
        const state = address?.administrative_area_level_1?.long_name ?? "";

        if (zipCode) {
            const zipCodeField = fields.find((i) => i.name === "zipCode");
            if (zipCodeField) onChange(zipCodeField, zipCode);
        }

        if (state) {
            const stateField = fields.find((i) => i.name === "state");
            if (stateField) onChange(stateField, `crmCompanyStates_${state}`);
        }

        const val = `${streetNumber} ${route}`.trim();

        onChange(field, val);
    };

    const handleGooglePlacesScriptLoaded = () => {
        if (streetAddressRef.current !== null) {
            autoComplete = new window.google.maps.places.Autocomplete(
                streetAddressRef.current,
                {
                    types: ["address"],
                    componentRestrictions: {
                        country: "us",
                    },
                    fields: [
                        "address_component",
                        "adr_address",
                        "alt_id",
                        "formatted_address",
                        "geometry",
                        "name",
                    ],
                },
            );
            autoComplete.addListener("place_changed", () => {
                handlePlaceSelect();
            });
        }
    };
    const loadGooglePlacesScript = (callback: () => void) => {
        if (document.getElementById("googleStreetAddress")) {
            callback();
            return;
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "googleStreetAddress";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${
            process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string
        }&libraries=places,geometry&callback=handleGooglePlacesScriptLoaded`;

        document.getElementsByTagName("head")[0].appendChild(script);
    };
    useEffect(() => {
        if (field.inputType === "streetAddress") {
            loadGooglePlacesScript(handleGooglePlacesScriptLoaded);
            // @ts-ignore
            window["handleGooglePlacesScriptLoaded"] =
                handleGooglePlacesScriptLoaded;
        }
    }, []);

    const gettingField = (type: ContactUsField["inputType"]) => {
        switch (type) {
            case "text":
                return (
                    <input
                        value={fieldState.value}
                        onChange={(e) => onChange(field, e.target.value)}
                        placeholder={field.placeholder}
                        autoComplete={field.autocomplete}
                        onBlur={(e) => onBlur(field, e.target.value)}
                        name={field.name}
                        id={field.name}
                        className={`${classes?.fieldClassName ?? ""} ${
                            styles["field"]
                        }`}
                    />
                );
            case "hidden":
                return (
                    <input
                        value={fieldState.value}
                        onChange={(e) => onChange(field, e.target.value)}
                        name={field.name}
                        id={field.name}
                        hidden
                    />
                );
            case "textarea":
                return (
                    <textarea
                        onChange={(e) => onChange(field, e.target.value)}
                        value={fieldState.value}
                        placeholder={field.placeholder}
                        autoComplete={field.autocomplete}
                        onBlur={(e) => onBlur(field, e.target.value)}
                        name={field.name}
                        id={field.name}
                        className={`${classes?.fieldClassName ?? ""} ${
                            styles["textarea"]
                        } ${styles["field"]}`}
                        rows={field.rows}
                    />
                );
            case "checkbox":
                return (
                    <input
                        onChange={(e) => {
                            onChange(field, String(e.target.checked));
                        }}
                        checked={!!fieldState.value}
                        type="checkbox"
                        name={field.name}
                        id={field.name}
                        className={`${styles["custom-checkbox"]} ${
                            classes?.checkBoxClassName ?? ""
                        }`}
                    />
                );
            case "checkbox-group":
                return (
                    <div
                        className={`${styles.checkboxGroup} ${
                            classes?.checkboxGroupClassName ?? ""
                        }`}
                    >
                        <label className="mr-3 lg:mr-10 text-sm sm:text-base">
                            {field.label}
                        </label>
                        {field?.options?.map((option, index) => (
                            <div
                                key={option.value}
                                className={styles["option"]}
                            >
                                <input
                                    type="checkbox"
                                    id={`${field.name}-${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    className="mx-3"
                                    checked={fieldState.value.includes(
                                        option.value,
                                    )}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const checked = e.target.checked;
                                        if (checked) {
                                            onChange(
                                                field,
                                                fieldState.value
                                                    ? `${fieldState.value},${value}`
                                                    : value,
                                            );
                                        } else {
                                            onChange(
                                                field,
                                                fieldState.value
                                                    ?.split(",")
                                                    .filter(
                                                        (item) =>
                                                            item !== value,
                                                    )
                                                    .join(","),
                                            );
                                        }
                                    }}
                                />
                                <label
                                    key={index}
                                    htmlFor={`${field.name}-${option.value}`}
                                    className="flex items-center "
                                >
                                    <span className={styles["box"]}>
                                        <svg viewBox="0,0,50,50">
                                            <path d="M5 30 L 20 45 L 45 5"></path>
                                        </svg>
                                    </span>
                                    <span className="text-sm">
                                        {option.label}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                );

            case "select":
                return (
                    <select
                        className={`${styles["field"]} ${styles["select"]} ${
                            !fieldState.value ? styles["empty"] : ""
                        } ${classes?.selectFieldClassName ?? ""}`}
                        onChange={(e) => onChange(field, e.target.value)}
                        name={field.name}
                        value={fieldState.value}
                        id={field.name}
                    >
                        {field.placeholder && (
                            <option value="">{field.placeholder}</option>
                        )}

                        {doNotSellForm
                            ? doNotSellFormStates?.map((option) => (
                                  <option key={option.id} value={option.value}>
                                      {option.label}
                                  </option>
                              ))
                            : field.options?.map((option) => (
                                  <option key={option.id} value={option.value}>
                                      {option.label}
                                  </option>
                              ))}
                    </select>
                );
            case "streetAddress":
                return (
                    <input
                        value={fieldState.value}
                        ref={streetAddressRef}
                        onChange={(e) => onChange(field, e.target.value)}
                        className={`${styles["field"]} ${
                            styles["streetAddress"]
                        } ${classes?.fieldClassName ?? ""}`}
                        placeholder={field.placeholder ?? undefined}
                        autoComplete={"off"}
                        type={type}
                        name={field.name ?? undefined}
                        onBlur={(e) => onBlur(field, e.target.value)}
                        id={field.name}
                    />
                );

            case "recaptcha":
                return (
                    <>
                        {domain?.recaptchaConfig?.key ??
                        domainInfo?.recaptchaConfig?.key ? (
                            <ReCAPTCHA
                                sitekey={
                                    domain?.recaptchaConfig?.key ??
                                    domainInfo?.recaptchaConfig?.key ??
                                    ""
                                }
                                onChange={(value) => {
                                    onChange(field, value ?? "");
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                );
        }
    };

    return (
        <div
            id={`${field.name}-field`}
            className={`${styles["field-wrapper"]} ${
                !fieldState.valid ? styles["has-error"] : ""
            } ${classes?.fieldWrapperClassName ?? ""}`}
            style={field.inputType === "hidden" ? { display: "none" } : {}}
        >
            {field.label && (
                <label
                    htmlFor={field.name}
                    className={
                        field.inputType === "checkbox"
                            ? fieldState.value === "true"
                                ? `${styles["checkboxLabel"]} ${styles["checkboxLabelChecked"]}`
                                : styles["checkboxLabel"]
                            : ""
                    }
                >
                    {field.label}
                </label>
            )}
            {gettingField(field.inputType)}
            <div
                className={`${styles["field-error"]} ${
                    classes?.fieldErrorClassName ?? ""
                }`}
            >
                <span>{fieldState?.errorMessage}</span>
            </div>
        </div>
    );
};
